import styled from "styled-components"

import { HomeGroupAddress } from "src/components/HomeGroups/HomeGroupAddress"
import { HomeGroupHomes } from "src/components/HomeGroups/HomeGroupHomes"
import { HomeGroupMembers } from "src/components/HomeGroups/HomeGroupMembers"
import {
  useFetchHomeGroup,
  usePatchHomeGroup,
} from "src/data/homeGroups/queries/homeGroupQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { Editable } from "src/ui/Editable/Editable"
import { BreadcrumbView } from "src/ui/Layout/BreadcrumbView"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"
import { ITab } from "src/ui/Tabs/Tab"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

export function HomeGroupDetails({ homeGroupId }: { homeGroupId: string }) {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const patchHomeGroup = usePatchHomeGroup()

  const userCanChangeName = getAccessLogic({
    role: org.user_role,
  }).hasAdminAccess

  function onTitleSave(name: string) {
    patchHomeGroup.mutate({ orgId: org.id, homeGroupId, body: { name } })
  }

  const fetchHomeGroup = useFetchHomeGroup({ orgId: org.id, homeGroupId })
  const isLoading = fetchHomeGroup.isInitialLoading
  const homeGroup = fetchHomeGroup.data
  const name = homeGroup?.name

  const tabs: ITab[] = [
    {
      id: Routes.HomeGroupDetails.tabs[0],
      label: t(langKeys.home_plural),
      view: !!homeGroupId ? (
        <HomeGroupHomes type={homeGroup?.type} id={homeGroupId} />
      ) : null,
    },
    {
      id: Routes.HomeGroupDetails.tabs[1],
      label: homeGroup?.type
        ? t(langKeys.homegroup_building_access_title)
        : t(langKeys.organizations_homegroup_access_title),
      view: !!homeGroupId ? (
        <HomeGroupMembers
          homeGroupId={homeGroupId}
          homeGroupRole={homeGroup?.user_role}
        />
      ) : null,
      placeholder: <MSkeleton variant="rect" width="100%" height={200} />,
    },
    {
      id: Routes.HomeGroupDetails.tabs[2],
      label: t(langKeys.address),
      view: !!homeGroupId ? (
        <HomeGroupAddress
          homeGroupId={homeGroupId}
          homeGroupAddress={homeGroup?.address}
          homeGroupLocation={homeGroup?.location}
          homeGroupRole={homeGroup?.user_role}
        />
      ) : null,
      placeholder: <MSkeleton variant="rect" width="100%" height={300} />,
    },
  ]

  if (fetchHomeGroup.isError) {
    return (
      <MBanner type="error">{t(langKeys.failed_general_error_title)}</MBanner>
    )
  }

  if (fetchHomeGroup.isLoading) {
    return <MSkeleton variant="rect" width="100%" height={300} />
  }

  return (
    <BreadcrumbView
      title={
        isLoading ? (
          <MSkeleton width={350} height={48} />
        ) : (
          <Editable onSave={onTitleSave} disabled={!userCanChangeName}>
            {name}
          </Editable>
        )
      }
      metaTitle={name}
      breadcrumbs={[
        {
          label: t(langKeys.properties),
          to: Routes.Homes.location(),
        },
        homeGroup?.type === "building"
          ? {
              label: t(langKeys.home_group_building, { count: 10 }),
              to: Routes.HomeGroupBuildings.location(),
            }
          : {
              label: t(langKeys.organizations_home_group, { count: 10 }),
              to: Routes.HomeGroupLocations.location(),
            },
        { label: name || "", to: { pathname: "#" } },
      ]}
      description={null}
      titleVariant="heading1"
    >
      <Tabs labels={tabs}>
        {tabs.map((tab) => (
          <StyledTabPanel key={tab.id}>
            {isLoading ? (tab.placeholder ?? null) : tab.view}
          </StyledTabPanel>
        ))}
      </Tabs>
    </BreadcrumbView>
  )
}

const StyledTabPanel = styled(TabPanel)`
  margin-top: ${spacing.XL2};
`
